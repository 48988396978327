import React from "react";
import { observer } from "mobx-react";
import { Title, Container, Section } from "bloomer";
import filesize from "filesize";

import compilationResultStore from "stores/compilationResultStore";

import { downloadFile } from "services/filesystem/localFilesystemService";

interface CompilationArtifactsElementProps {
    files: FilesystemFile[];
    onClickDownload: ( e: React.MouseEvent, path: string ) => void;
}

export const CompilationArtifactsElement: React.FC<CompilationArtifactsElementProps> = observer( ({ files, onClickDownload }) => {
    if( !files || files.length === 0 ) {
        return null;
    }

    return <Section>
        <Container>
            <hr />
            <Title>
                Generated files
            </Title>

            <ul>
                {files.map( file => <li key={file.path}>
                    <a href="#" onClick={( e ): void => onClickDownload( e, file.path )}>
                        {file.filename}
                    </a>
                    {" "}({filesize( file.size )})
                </li> )}
            </ul>
        </Container>
    </Section>;
});

/**
 * Lists the files generated by the compiler and let the user download them
 */
const CompilationArtifacts: React.FC = observer( () => {
    const onClick = ( e: React.MouseEvent, path: string ): void => {
        e.preventDefault();
        downloadFile( path, true );
    };

    return <CompilationArtifactsElement files={compilationResultStore.artifacts} onClickDownload={onClick} />;
});

export default CompilationArtifacts;
