export const publishingConfig = {
    apiKey: "AIzaSyC2Qd1EvukPKW9ZPAoh-SBAMudP9-z4LfE",
    authDomain: "borogove-io.firebaseapp.com",
    databaseURL: "https://borogove-io-default-rtdb.firebaseio.com",
    projectId: "borogove-io",
    storageBucket: "borogove-io-pending",
    messagingSenderId: "93236866839",
    appId: "1:93236866839:web:4e83c2fb30423b4294d152",
    measurementId: "G-FKY133XHD6"
};

export const snippetsConfig = {
    apiKey: "AIzaSyCNaPF0uWxzrw68MEeIwdjVzRxiIeF_Ewg",
    authDomain: "borogove-ide.firebaseapp.com",
    databaseURL: "https://borogove-ide.firebaseio.com",
    projectId: "borogove-ide",
    storageBucket: "borogove-ide.appspot.com",
    messagingSenderId: "571355987682",
    appId: "1:571355987682:web:7d949700c4b7cabf5628bb",
    measurementId: "G-Y3RG7241MC"
};
